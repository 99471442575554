import React, { useContext, useEffect, useState } from "react";
import * as inputStyles from "../search-input.module.css"
import Select from 'react-select'
import * as buttonStyles from '../button.module.css'
import { validateEmail } from "../utils"
import { Notification, NotificationType } from "../notification"
import { API, graphqlOperation } from "aws-amplify"
import { LoadMaskContext } from "../../provider/loadmaskprovider";
import { getTenant, getVerifiedUserTenantsByTenant } from "../../graphql/queries";
import AsyncSelect from 'react-select/async';
import { UserTenantProvider } from "../../provider/usertenantprovider";

const createObservation = /* GraphQL */ `mutation createObservation($input: CreateObservationInput!){
  createObservation(input: $input){
    id
    createdAt
  }
}`

const listTenants = /* GraphQL */ `
  query listTenants {
  listTenants {
    items {
      prettyName
      name
      adminGroup
    }
  }
}
`
const listUserEmails= /* GraphQL */ `
  mutation observerUserLookup($emailAddress: String) {
    observerUserLookup(emailAddress: $emailAddress) {
    items {
      email
      username
    }
  }
}
`
const resultOptions = [
  {label: "Positive", value: "Positive"},
  {label: "Negative", value: "Negative"},
  {label: "Invalid", value: "Invalid"}
]

const categoryOptions = [
  {label: "Weekly", value: "Weekly"},
  {label: "Symptoms", value: "Symptoms"}
]

const productOptions = [
  {label: "FlowFlex Antigen Test", value: "FlowFlex Antigen Test"}
]

const Observation = props => {
  const emptyState = {
    tenant: null,
    tenantProvidedUserId: "",
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
    result: null,
    category: categoryOptions[0],
    productName: productOptions[0]
  }

  const [isLoading, setIsLoading] = useContext(LoadMaskContext);
  const [message, setMessage] = useState({
    show: false,
  })

  let timer = void 0
  const debounce = fn => {
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn(...args);
        }, 500);
    }
  }

  const save = async () => {
    setMessage({ show: false });
    //Use createdAt for the observation. Don't bother the Observer
    let s = {...state}
    let valid = true
    for(let key in s){
      //This may change and be required.  For now, optional
      if(key === "tenantProvidedUserId" || key === "notes")
        continue
      if(!s[key]){
        valid = false
        break
      }
    }
    if(!valid){
      setMessage({
        show: true,
        msg: "Please enter all required fields.",
        messageType: NotificationType.FAIL,
      })
    }
    else if(!validateEmail(s.email.value.email) ){
      setMessage({
        show: true,
        msg: "Please make sure you've entered a valid email address.",
        messageType: NotificationType.FAIL,
      })
    }
    else{
      console.log("Saving observation details.")
      let graphqlInput = {...s}
      graphqlInput.result = s.result.value
      graphqlInput.productName = s.productName.value
      graphqlInput.category = s.category.value
      graphqlInput.tenant = s.tenant.value.name
      graphqlInput.email = s.email.value.email
      graphqlInput.userId = s.email.value.username
      graphqlInput.tenantManagerGroup = s.tenant.value.adminGroup
      console.log(graphqlInput)
      setIsLoading(true);
      try {
        await API.graphql(
          graphqlOperation(createObservation, {input: graphqlInput})
        );
        setMessage({
          show: true,
          msg: 'Successfully saved an observation.',
          messageType: NotificationType.SUCCESS,
        });
        
        setState(emptyState)
      } catch (error) {
        setMessage({
          show: true,
          msg: 'Failed to save observation.',
          messageType: NotificationType.FAIL,
        });
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const [state, setState] = useState(emptyState)
  const [tenantOptions, setTenantOptions] = useState([])

  useEffect(()=>{
    getTenants()
  },[])

  const _filterEmails = (inputValue,callback) => {
    API.graphql(
      graphqlOperation(listUserEmails, {emailAddress: `email ^= "${inputValue}"`})
      ).then(function(result){
        console.log(result)
        let options = result.data.observerUserLookup.items.map(u =>{
          let opt = {
            label: u.email,
            value: {
              "email": u.email, 
              "username": u.username
            }
          }
          return opt
        })
        callback(options)
      });
  };

  const filterEmails = debounce(_filterEmails)
  
  const loadOptions = (inputValue,callback) => {
    setTimeout(() => {
      filterEmails(inputValue,callback)
    }, 1000);
  };

  const getTenants = async () => {
    try{
      let result = await API.graphql(graphqlOperation(listTenants))
      let tenantObjects = result.data.listTenants.items
      const tenantOptions = []
      for(const tenant of tenantObjects){
        let option = {
          label: tenant.prettyName,
          value: {
            "name" : tenant.name,
            "adminGroup" : tenant.adminGroup
          }
        }
        tenantOptions.push(option)
      }
      setTenantOptions(tenantOptions)
    }catch(error){}
  }

  return (
    <div>
      <div style={{width:"100%"}}>
      {message.show &&
        <Notification messageType={message.messageType}>
          <p>{message.msg}</p>
        </Notification>
      }
      <label>
          First name
          <input value={state.firstName} name="firstName" style={{margin:"0 0 15px 0", width:"100%"}} type="text" className={inputStyles.inputcss} 
          onChange={e => {
            let s = {...state}
            s["firstName"] = e.currentTarget.value
            setState(s)
          }} />
        </label>
        <label>
          Last name
          <input value={state.lastName} name="lastName" style={{margin:"0 0 15px 0", width:"100%"}} type="text" className={inputStyles.inputcss} 
          onChange={e => {
            let s = {...state}
            s["lastName"] = e.currentTarget.value
            setState(s)
          }} />
        </label>
        <div style={{margin:"0 0 15px 0", width:"100%"}}>
          <label>
            Email
          <AsyncSelect value={state.email} placeholder="Enter Email Address" cacheOptions defaultOptions loadOptions={loadOptions} 
            onChange={e => {
              let s = {...state}
              s["email"] = e
              setState(s)
            }}
          />
          </label>
        </div>
        <label>
          Tenant provided ID
          <input value={state.tenantProvidedUserId}  name="indentifier" style={{margin:"0 0 15px 0", width:"100%"}} type="email" className={inputStyles.inputcss} 
          placeholder="Optional"
          onChange={e => {
            let s = {...state}
            s["tenantProvidedUserId"] = e.currentTarget.value
            setState(s)
          }}/>
        </label>
        <div style={{margin:"0 0 15px 0", width:"100%"}}>
          <label>
            Tenant
            <Select value={state.tenant} options={tenantOptions} placeholder="Select Tenant" 
            onChange={e => {
              let s = {...state}
              s["tenant"] = e
              setState(s)
            }} />
          </label>
        </div>
        <div style={{margin:"0 0 15px 0", width:"100%"}}>
          <label>
            Category
            <Select value={state.category} options={categoryOptions} placeholder="Select Test Category" 
            onChange={e => {
              let s = {...state}
              s["category"] = e
              setState(s)
            }} />
          </label>
        </div>
        <div style={{margin:"0 0 15px 0", width:"100%"}}>
          <label>
            Product name
            <Select value={state.productName} options={productOptions} placeholder="Select Product" 
            onChange={e => {
              let s = {...state}
              s["productName"] = e
              setState(s)
            }} />
          </label>
        </div>
        <label>
          Notes
          <textarea value={state.notes} name="notes" style={{margin:"0 0 15px 0", width:"100%"}} type="text" className={inputStyles.inputcss} 
          onChange={e => {
            let s = {...state}
            s["notes"] = e.currentTarget.value
            setState(s)
          }} />
        </label>
        <div style={{margin:"0 0 15px 0"}}>
          <label>
            Result
            <Select value={state.result}  options={resultOptions} placeholder="Select Test Result" 
            onChange={e => {
              let s = {...state}
              s["result"] = e
              setState(s)
            }} />
          </label>
        </div>
        <button className={buttonStyles.button} onClick={save} >Save</button>
      </div>
    </div>
  )
}

export default Observation
