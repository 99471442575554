import React, { useContext } from "react"
import { UserContext } from "../../provider/usercontextprovider"
import CustomAuth from "../../components/custom-auth";
import SEO from "../../components/seo";
import Observation from "../../components/observation/observation";

const Observations = ({ location }) => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isObserver = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('Observer');
  return (
    <CustomAuth>
      <SEO title="Observation" />
      <div style={{ justifyContent: "center", display: "flex" }}>
      {isObserver ? <Observation/> : <p>User is not a Observer.</p>}
      </div>
      
    </CustomAuth>
  )
}

export default Observations
