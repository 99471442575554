import React from "react"
import * as notificationStyles from "./notification.module.css"

const NotificationType = {
    FAIL: 'FAIL',
    SUCCESS: 'SUCCESS'
}

const NotificationTypeMap = {
    FAIL: {
        cls: 'fail',
        icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
    },
    SUCCESS: {
        cls: 'success',
        icon: 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
    }
};

const Notification = props => {
    const messageType = props.messageType == 'SUCCESS' ? NotificationTypeMap.SUCCESS : NotificationTypeMap.FAIL
    const hideIcon = props.hideIcon
    return (
        <div className={notificationStyles[messageType.cls]}>
            <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{display:hideIcon ? 'none' : 'initial'}}>
                <path fillRule="evenodd" d={messageType.icon} clipRule="evenodd" />
            </svg>
            {props.children}
        </div>
    )
}
  
  export { Notification, NotificationType }